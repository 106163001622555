<template>
  <v-container class="d-flex justify-center align-center" fill-height>
    <v-dialog v-model="modalOpen" max-width="400px" persistent>
      <div class="d-flex flex-column align-center pa-5">
        <div
          class="d-flex justify-center align-center mb-4 rounded-lg"
          style="
            width: 80%;
            height: 250px;
            background: #fff;
            border: 4px solid #a864f0;
          "
        >
          <!-- Aquí irá el componente de escaneo -->
        </div>

        <p class="text-center white--text mb-5" style="width: 10rem">
          Apunta la cámara al código de barras
        </p>
        <v-icon size="48" color="white" class="my-3">mdi-barcode-scan</v-icon>

        <!-- Botón para ingresar código manualmente -->
        <v-btn
          block
          color="primary"
          dark
          rounded
          @click="redirectToNewProduct"
          class="py-6 text-none text-body-1"
        >
          <v-icon size="28" left class="mr-3">mdi-gesture-tap-button</v-icon>
          Ingresar código manualmente
        </v-btn>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      modalOpen: true, // Controla el estado del modal
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true; // Abre el modal
    },
    closeModal() {
      this.modalOpen = false; // Cierra el modal
    },
    redirectToNewProduct() {
      this.$router.push({ name: "new-product-g" });
    },
  },
};
</script>

<style scoped>
::v-deep .v-dialog {
  box-shadow: none !important;
}
</style>
